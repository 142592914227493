// BASE
import React from 'react';
import {BrowserRouter} from "react-router-dom";
// Routes
import UnitagRoutes from "./routes";
// Layout
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
// Stripe
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
// Contexts
import {DashboardUiProvider, ErrorBoundaryProvider, UserProvider} from "@context";

const {REACT_APP_STRIPE_KEY} = process.env;

function App() {
    const stripePromise = loadStripe(REACT_APP_STRIPE_KEY!);

    return (
        <DashboardUiProvider>

            <Elements stripe={stripePromise}>
                <BrowserRouter>
                    <div className="App">
                        {/* New providers*/}
                        <ErrorBoundaryProvider>
                            <UserProvider>
                                {/* Old providers and app structure*/}
                                    <Navbar>
                                        <UnitagRoutes />
                                    </Navbar>
                                <Footer/>
                            </UserProvider>
                        </ErrorBoundaryProvider>

                    </div>
                </BrowserRouter>
            </Elements>

        </DashboardUiProvider>
    );
}

export default App;
