import React, {useState, useEffect} from 'react';
// MUI
import {
    FormControl, Grid, TextField, Container, Divider, Typography
} from '@mui/material';
// Unitag UI
import {Button, PasswordCheckTooltip} from '@components';
// TRANSLATION
import {useTranslation} from "react-i18next";
// AUTH
import {Auth} from "aws-amplify";
import TwoFASetup from "../components/Security/TwoFASetup";
import {useHistory} from "react-router-dom";

export default function SettingsSecurityTab(props: any) {

    const {t} = useTranslation(['settings', 'common']);
    const {handleError, handleSuccess} = props
    const history = useHistory();

    const classes = {
        paper: {padding: '16px'},
        text: {color: "#4f566b", maxWidth: "80%"},
        textField: {marginLeft: '8px', marginRight: '8px', width: '300px', my: 0},
        form: { my: 1 }
    };

    const [newPassword, setNewPassword] = useState<string | null>(null);
    const [currentPassword, setCurrentPassword] = useState<string | null>(null);
    // Password validity
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)
    // 2FA
    const [is2FAActive, set2FAActive] = useState<boolean>(false);
    const [open2FA, setOpen2FA] = useState<boolean>(false);

    useEffect(() => {
        // Check if 2FA is enabled
        Auth.currentAuthenticatedUser()
            .then(user => {
                Auth.getPreferredMFA(user, {bypassCache: true})
                    .then((data) => {
                        // If NOMFA then there is no MFA active
                        if (data === "NOMFA") set2FAActive(false);
                        else set2FAActive(true);

                })
            }).catch(() => handleError(t("unknown_error")))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const updatePassword = () => {
        if (currentPassword !== null && newPassword !== null) {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    return Auth.changePassword(user, currentPassword, newPassword);
                })
                .then(() => {
                    handleSuccess(t("Password_update_success"))
                    Auth.signOut({ global: true }).then(() => {
                        history.go(0);
                        history.push("/login");
                    })
                })
                .catch(() => handleError(t("Password_update_error")))
        }
    }

    return (
        <main>
            <Container maxWidth="xl">
                <Grid container justifyContent={'flex-start'}>

                    <Grid item xs={12} sx={{ py: 1 }}>
                        <Typography variant='h5' gutterBottom>{t('Password_change')}</Typography>
                        <Divider/>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" sx={classes.form}>
                            <TextField
                                label={t("Password_old")}
                                type="password"
                                defaultValue=""
                                sx={classes.textField}
                                helperText={t("Password_old_label")}
                                margin="dense"
                                variant="outlined"
                                size={"small"}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                        </FormControl>

                        <FormControl fullWidth variant="outlined" sx={classes.form}>
                            <PasswordCheckTooltip password={newPassword} {...{setIsPasswordValid}}>
                                <TextField
                                    label={t("Password_new")}
                                    type="password"
                                    defaultValue=""
                                    error={!!newPassword && !isPasswordValid}
                                    sx={classes.textField}
                                    margin="dense"
                                    variant="outlined"
                                    size={"small"}
                                    onChange={(e) => setNewPassword(e.currentTarget.value)}
                                />
                            </PasswordCheckTooltip>
                        </FormControl>

                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            primary
                            type="submit"
                            onClick={updatePassword}
                            disabled={!currentPassword || !newPassword || !isPasswordValid}
                        >
                            {t("Password_update")}
                        </Button>
                    </Grid>

                    <Grid item xs={12} sx={{ py: 1, mt: 3 }}>
                        <Typography variant='h5' gutterBottom>{t('2fa')}</Typography>
                        <Divider/>
                        <br/>
                        <Typography variant='body1' sx={classes.text}>
                            {t("Security_two_factor_auth")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            primary
                            type="submit"
                            onClick={() => setOpen2FA(true)}
                            disabled={is2FAActive}
                        >
                            {t("Security_activate_2fa")}
                        </Button>
                    </Grid>
                </Grid>

                <TwoFASetup open={open2FA} onClose={() => setOpen2FA(false)}/>
            </Container>
        </main>
    );
}