// BASE
import React, {useEffect} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
// Material UI
import {
    AppBar as MUIAppBar,
    AppBarProps as MUIAppBarProps,
    Toolbar,
    Typography,
    Box
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {styled} from '@mui/material/styles';
//SVGs
import {ReactComponent as UnitagLogo} from "@assets/svg/logo-unitag.svg";
// API
import {Auth} from 'aws-amplify';
//TRANSLATION
import {useTranslation} from "react-i18next";
// Unitag UI
import {Button} from '@components';
// Components
import NotificationCenter from "./components/notificationMenu/notificationCenter";
import DevMenu from "./components/devMenu/DevMenu";
import LanguageMenu from './components/languageMenu/LanguageMenu';
import NavbarDrawer from "./components/navbarDrawer/NavbarDrawer";
import AccountMenu from "./components/accountMenu/AccountMenu";
// Context (new)
import {useUser} from "@context";

// Private
const drawerWidth = 300
const {REACT_APP_ENV} = process.env;

// Types & Props
interface AppBarProps extends MUIAppBarProps {
    open?: boolean;
    children: any,
}

const AppBar = styled(MUIAppBar, {shouldForwardProp: (prop) => prop !== 'open'})<AppBarProps>(
    ({theme, open}) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

export default function Navbar({children}: any) {

    const query = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const {credentials} = useUser()
    const {t} = useTranslation(['navigation', 'common']);

    const styles = {
        // navbar
        unitagLogo: {height: '35px', width: 'auto', fill: '#ffffff'},
        // drawer
        listHeader: {justifyContent: 'flex-end'},
        drawerStaticDivider: {my: "20px"},
        drawerAppButtons: {my: "5px"},
        // menu
        menuTextAnimationWrapper: {
            animation: "spin 1s linear",
            "@keyframes spin": {
                "10%": {
                    transform: "rotate(10deg)",
                },
                "50%": {
                    transform: "rotate(-10deg)",
                },
                "100%": {
                    transform: "rotate(0deg)",
                },
            }
        },


        accountMenuPrimary: {
            fontWeight: 400,
            fontSize: '14px',
            color: '#676767',
        },
        accountMenuSecondary: {
            fontWeight: 300,
            fontSize: '14px',
            color: '#676767',
        },
        accountMenuElearning: {
            minWidth: '180px',
            fontWeight: 700,
            fontSize: '14px',
            color: 'secondary.main',
        },

        accountMenuIcons: {
            minWidth: 24
        }
    };

    const {
        getUser,
        user: globalUser,
        getComplexUser, complexUser,
        getOrganisationAsMember,
        organisationAsMember
    } = useUser()

    // Sub-menus & drawers opening states
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
    const [accountMenuAnchor, setAccountMenuAnchor] = React.useState<null | HTMLElement>(null);

    // Lifecycles
    // Get user or redirect if not logged in
    useEffect(() => {
        // URL reading
        const path = window.location.pathname;

        getUser().then(() => {}, () => redirectLoggedOutUser(path))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentials]);

    // Hydrate contexts and states
    useEffect(() => {
        if (!!globalUser) {
            getComplexUser()

            if (!!globalUser.org) getOrganisationAsMember()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalUser])

    const redirectLoggedOutUser = (path: any) => {
        const product = query.get("product");

        switch (path) {
            case "/signup":
                if (product) {
                    history.replace(`/signup?product=${product}`);
                    break;
                }
                history.replace("/signup");
                break;
            case "/google_sso":
                history.replace("/google_sso");
                break;
            default:
                if (product) {
                    history.replace(`/login?product=${product}`);
                    return;
                }
                history.replace("/login");
        }
    }

    // Logout
    const logout = () => {
        Auth.signOut().then(() => {
            setAccountMenuAnchor(null)
            history.go(0);
            history.push("/login");
        });
    }

    if (!globalUser) return (
        <>
            {children}
        </>
    )


    return (
        <>
            <AppBar position="fixed" color="primary">
                <Toolbar>

                    {/* Left section */}
                    <Button text component={Link} to="/" aria-label="home">
                        <UnitagLogo style={styles.unitagLogo}/>
                    </Button>

                    {
                        window.self !== window.top ?
                            null
                            : <Button
                                startIcon={<MenuIcon/>}
                                text
                                spacing
                                aria-label="menu"
                                onClick={() => setDrawerOpen(true)}
                            >
                                <Box sx={styles.menuTextAnimationWrapper}>
                                    {t("Menu")}
                                </Box>
                            </Button>

                    }

                    <Typography variant="h6" component={"p"} flexGrow={1}></Typography>

                    {/* Right section */}
                    <DevMenu {...{REACT_APP_ENV}} />

                    {
                        window.self !== window.top ?
                            null
                            : <>
                                <LanguageMenu/>

                                <NotificationCenter/>

                                <AccountMenu
                                    anchor={accountMenuAnchor}
                                    onOpen={(e: any) => setAccountMenuAnchor(e.currentTarget)}
                                    onClose={() => setAccountMenuAnchor(null)}
                                    {...{setAccountMenuAnchor, globalUser, organisationAsMember, logout, styles, t}}
                                />
                            </>
                    }


                </Toolbar>
            </AppBar>

            <Box style={{marginTop: "65px", marginLeft: "64px"}}>
                {children}
            </Box>

            {
                window.self !== window.top ?
                    null
                    : <NavbarDrawer
                        open={drawerOpen}
                        setOpen={setDrawerOpen}
                        onClose={() => setDrawerOpen(false)}
                        {...{drawerWidth, globalUser, complexUser, styles, t}}
                    />

            }
        </>
    );
}
