import React from "react"
import {Link} from "react-router-dom";
// MUI
import {Divider, IconButton, List, ListItem, ListItemText, ListItemIcon, Menu, MenuItem, Tooltip, Typography} from "@mui/material";
import {AccountCircle, Logout, Settings, School} from "@mui/icons-material";

export default function AccountMenu(props: any) {

    const {
        anchor,
        onOpen, onClose,
        styles, t,

        globalUser, organisationAsMember,
        logout
    } = props

    const renderAccountType = () => {

        let accountType = globalUser?.account_type

        if (!!organisationAsMember) accountType = organisationAsMember.org_account_type

        switch (accountType) {
            case "free":
                return<Typography>{t("Free")}</Typography>
            case "standalone":
                return<Typography>{t("Standalone")}</Typography>
            case "standard":
                return <Typography>Standard</Typography>
            case "gold":
                return <Typography>Gold</Typography>
            case "platinum":
                return <Typography>Platinum</Typography>
        }
    }

    return (
        <>
            <Tooltip arrow title={`${t("Your_account")}`}>
                <IconButton
                    aria-label={t("Your_account")}
                    aria-controls="userAppbarMenu"
                    aria-haspopup="true"
                    onClick={onOpen}
                    color="inherit"
                >
                    <AccountCircle/>
                </IconButton>
            </Tooltip>

            <Menu
                id="account-menu"
                anchorEl={anchor}
                keepMounted
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                transformOrigin={{vertical: "top", horizontal: "center"}}
                open={Boolean(anchor)}
                onClose={onClose}
            >
                <List>
                    <ListItem style={{marginTop: "-8px"}}>
                        <ListItemText
                            disableTypography
                            sx={styles.accountMenuSecondary}
                            primary={t("Signed_in_as")}
                            secondary={(<Typography>{globalUser?.email}</Typography>)}
                        />
                    </ListItem>

                    {
                        !!organisationAsMember &&
                        <ListItem style={{marginTop: '-20px'}}>
                            <ListItemText
                                disableTypography
                                sx={styles.accountMenuSecondary}
                                primary={t("Member_of")}
                                secondary={(<Typography>{organisationAsMember.organisation_name}</Typography>)}
                            />
                        </ListItem>
                    }

                    <ListItem style={{marginTop: '-20px'}}>
                        <ListItemText
                            disableTypography
                            sx={styles.accountMenuSecondary}
                            primary={t("Account_type")}
                            secondary={(renderAccountType())}
                        />
                    </ListItem>
                    <Divider/>
                </List>

                {
                    globalUser?.account_type === "platinum" &&
                    <div>
                        <MenuItem sx={styles.accountMenuElearning} component={Link} to={"/e-learning"} onClick={onClose} color={"secondary"}>
                            <ListItemIcon><School fontSize={"small"} /></ListItemIcon>
                            {t("elearning")}
                        </MenuItem>
                        <Divider />
                    </div>
                }

                <MenuItem component={Link} to="/settings" sx={styles.accountMenuPrimary} onClick={onClose}>
                    <ListItemIcon><Settings fontSize={"small"} /></ListItemIcon>
                    {t("Settings")}
                </MenuItem>

                <MenuItem component={Link} to="/login" sx={styles.accountMenuPrimary} onClick={logout}>
                    <ListItemIcon><Logout fontSize={"small"} /></ListItemIcon>
                    {t("Logout")}
                </MenuItem>
            </Menu>
        </>
    )
}